import React, {useEffect, useMemo, useState} from 'react'
import useStore from '@/zustand/sotre'
import {Site} from '@/openapi/api'
import { SiteWithObjective } from '@/zustand/slice/storeSlice'
import {MenuItemProps} from '@/components/atoms/SelectField'
import {ALL_SITES} from '@/constant/sites'


export default function useSiteManagement() {
    const {
        storeState,
        analysisState,
        setSelectedSite,
        setAnalysisState,
    } = useStore()
    const [siteOptions, setSiteOptions] = useState<MenuItemProps[]>([])
    const [selectSite, setSelectSite] = useState<MenuItemProps | null>(ALL_SITES)
    const [childSites, setChildSites] = useState<Site[] | SiteWithObjective[] | undefined>(undefined)
    const [bottomSites, setBottomSites] = useState<Site[] | SiteWithObjective[] | undefined>(undefined)
    const site = useMemo(() => selectSite,
        [selectSite],
    )

    //========================================================
    //                       関数とか
    //========================================================

    // 拠点ドロップダウンのリストを作成
    // グローバルストアの選択拠点更新
    const setSiteDropDown = (sites: Site[]) => {
        if (!sites) return
        // ドロップダウンオプションを作成
        // 企業全体を含む
        const options = [
            ALL_SITES,
            ...sites.map((s) => ({ id: s.id, value: s.name }))
        ]
        setSiteOptions(options)
    }

    const handleSiteChange = (e: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>) => {
        if (e.target.value === undefined || e.target.value === null) {
            return
        }
    
        const selectedOption = siteOptions.find(s => s.id === e.target.value as number)
        if (!selectedOption) {
            return
        }

        // ローカルの選択状態を更新
        setSelectSite(selectedOption)

        // グローバルストアの更新
        updateGlobalStore(selectedOption)

        // 選択された拠点とその子拠点を取得
        setChildSites(getChildSites(e.target.value as number))
        // 選択された拠点の最下層拠点を取得
        setBottomSites(getBottomSites(e.target.value as number))
    }

    // グローバルストアselectedSiteの更新
    const updateGlobalStore = (selectedOption: MenuItemProps | undefined) => {
        if (selectedOption == undefined) return
        // ドロップダウンリストで企業全体が選択されていたらstoreStateの最初の拠点をセット
        if (selectedOption.id === ALL_SITES.id) {
            if (storeState.sites.length > 0) {
                setSelectedSite(storeState.sites[0])
                setAnalysisState({
                    ...analysisState,
                    selectedSite: storeState.sites[0]
                })
            }
        } else {
            const selectedSite = storeState.sites.find(s => s.id === selectedOption.id)
            if (selectedSite) {
                setSelectedSite(selectedSite)
                setAnalysisState({
                    ...analysisState,
                    selectedSite: selectedSite
                })
            }
        }
    }

    // 選択された拠点と有効な子拠点を再帰的にすべて取得
    const getChildSites = (siteId: number): Site[] | SiteWithObjective[] => {
        const sites = storeState.sites
        if (siteId === 0) {
            return sites.filter(s => s.status === 1)
        }

        const parent = sites.find(s => s.id === siteId)
        if (!parent) return [];

        const children = [parent]
        const childSites = getChildOfASite(siteId)
        for (const member of childSites) {
            const child = getChildSites(member.id)
            children.push(...child)
        }
        return children
    }

    // 選択された拠点の小拠点のうち、活動量登録可能拠点（最下層の拠点）をすべて取得
    const getBottomSites = (siteId: number): Site[] | SiteWithObjective[] => {
        const childSites = getChildSites(siteId)
        const bottomSites = []
        for (const member of childSites) {
            const non = childSites.some(s => s.parentSiteId === member.id)
            if (!non) bottomSites.push(member)
        }
        return bottomSites
    }

    // 特定の拠点の直下の有効な小拠点を取得する
    const getChildOfASite = (siteId: number): Site[] | SiteWithObjective[] => {
        const sites = storeState.sites
        const childSites = sites.filter(s => s.parentSiteId === siteId && s.status === 1)
        return childSites
    }
    //========================================================
    //                       useEffect
    //======================================================== 
    // 拠点まわりの初期設定
    //      選択拠点を初期セット
    //      それに合わせてグルーバルストアを更新
    useEffect(() => {
        // 選択拠点をセット
        const initialSite = selectSite || ALL_SITES
        setSelectSite(initialSite)

        // グローバルストアの更新
        updateGlobalStore(initialSite)
    }, [])

    // 必要な拠点の集まりをセット
    useEffect(() => {
        setChildSites(getChildSites(selectSite?.id || 0))
        setBottomSites(getBottomSites(selectSite?.id || 0))
    }, [selectSite])

    return {
        site,
        siteOptions,
        setSiteOptions,
        selectSite,
        childSites,
        bottomSites,
        setSiteDropDown,
        handleSiteChange,
        updateGlobalStore,
        getChildSites,
        getBottomSites,
        setSelectSite
    }

}

