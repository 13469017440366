import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import {withLayout} from '@/components/template/Layout'
import {makeMonth, siteActivityApi, siteSubmissionApi} from '@/ghgApi'
import useAnalysis from '@/hooks/useAnalysis'
import useSiteManagement from '@/hooks/useSiteManagement'
import {
    ActivityWithEmissionFactor,
    SaveActivity, SubmissionStatus
} from '@/openapi/api'
import useStore from '@/zustand/sotre'
import {makeStyles} from '@material-ui/core'
import React, {useEffect, useMemo, useState} from 'react'
import YearSelector from "@/components/template/Analysis/Selector/YearSelector";
import OutlineButton from "@/components/atoms/Button/OutlineButton";
import UploadFileIcon from "@/components/atoms/Icon/svg/UploadFileIcon";
import styled from 'styled-components'
import * as Encoding from "encoding-japanese";
import Papa, {ParseConfig} from "papaparse";
import {InputDate} from "@/zustand/slice/inputSlice";
import groupby from 'lodash.groupby'
import {ALL_SITES} from '@/constant/sites'


// ====================================================================================
// Overview
// ====================================================================================
interface ActivityWithEfAndSiteId extends ActivityWithEmissionFactor {
    siteId: number;
}

interface SaveActivityWithSiteId extends SaveActivity {
    siteId: number;
}

const MAX_DECIMAL_DIGIT = 6

const Input = styled.input`
    display: none;
`

const useStyles = makeStyles({
    operationTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
        fontWeight: 300,
        fontSize: 16,
    },
})

const OverviewInner = () => {
    const {
        storeState,
        analysisState,
        setMessage
    } = useStore()
    const {
        site,
        siteOptions,
        childSites,
        setSiteDropDown,
        handleSiteChange,
        setSelectSite
    } = useSiteManagement()
    const classes = useStyles()
    const { setAnalysisDate } = useAnalysis()
    const [loading, setLoading] = useState(false)
    const [doResetData, setDoResetData] = useState(false)
    const [activities, setActivities] = useState<ActivityWithEfAndSiteId[] | undefined>(undefined)

    // 組織の選択した年度を月毎に取得
    const oneYearDates = useMemo(() => {
        let dates: InputDate[] = []
        if (storeState.organization?.startMonth) {
            for (let i = 0; i < 12; i++) {
                const month = storeState.organization.startMonth + i
                if (month > 12) {
                    dates.push({year: analysisState.date.year + 1, month: month - 12})
                } else {
                    dates.push({year: analysisState.date.year, month: month})
                }
            }
        }
        return dates
    }, [storeState.organization, analysisState.date])

    //========================================================
    //                       useEffect
    //========================================================        
    useEffect(() => {
        const sites = storeState.sites
        const sitesExludesMiddle = sites.filter(member => {
            if (member.status !== 1) return false
            if (member.parentSiteId == null) return true
            if (sites.some(s => s.parentSiteId === member.id)) return false
            return true
        })
        setSiteDropDown(sitesExludesMiddle)
    }, [])

    // 全拠点の活動量を取得
    useEffect(() => {
        setActivities(undefined)
        const fetchData = async () => {
            try {
                let allActivities: ActivityWithEfAndSiteId[] = []
                for (const site of storeState.sites) {
                    try {
                        const response = await siteActivityApi.getSiteYearActivityWithEmissionFactor(
                            site.id,
                            analysisState.date.year
                        )
                        if (response?.data?.data) {
                            const activitiesWithSiteId = response.data.data.map(activity => ({
                                ...activity,
                                siteId: site.id
                            }))
                            allActivities = [...allActivities, ...activitiesWithSiteId]
                        }
                    } catch (err) {
                        console.warn('SiteActivityApi.getSiteYearActivity:', err)
                    }
                }
                setActivities(allActivities)
            } catch(err) {
                console.warn('error at fetchData:', err)
            }
        }

        fetchData()
    }, []);

    //========================================================
    //                       関数とか
    //========================================================

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0]
        if (file) {
            let reader = new FileReader()

            reader.onload = onLoadCSV

            reader.readAsArrayBuffer(file)

            // 同じファイルを連続でアップロードできるようにするため
            e.target.value = null
        }
        function onLoadCSV(e: ProgressEvent<FileReader>) {
            const yearIndex = 5
            const monthIndex = 6
            const quantityIndex = 11
            const eFIdIndex = 15
            const categoryIndex = 16
            const siteIdIndex = 17
            const memoIndex = 18

            if (e && e.target) {
                const codes = new Uint8Array(e.target.result as ArrayBuffer)
                const encoding = Encoding.detect(codes)
                const unicodeString = Encoding.convert(codes, {
                    to: 'UNICODE',
                    from: encoding as Encoding.Encoding,
                    type: 'string',
                })
                const parseConfig: ParseConfig = {
                    skipEmptyLines: true,
                }
                const data = Papa.parse(unicodeString, parseConfig).data
                const headlessData: string[][] = data.slice(1).map((d: string[]) => {
                    const result = d.map((inner, i) => {
                        if (i === 1) {
                            const replaceName: string = inner.split(' > ').reverse().shift() || inner
                            return replaceName
                        }
                        return inner
                    })
                    return result
                })

                const errors = validate(headlessData)
                if (!errors.length) {
                    importData(headlessData)
                } else {
                    setMessage({ message: errors[0], type: 'error' })
                    errors.forEach((error) => console.warn(error))
                }
            }

            //バリデーション
            function validate(headlessData: Array<any>): Array<string> {
                if (!childSites) return []
                const errors = new Array<string>()
                headlessData.forEach((row, index) => {
                    // 活動量
                    const quantity = row[quantityIndex]
                    // 活動量がnumberであること
                    if (isNaN(Number(quantity))) errors.push(`${index + 1}番目：活動量は番号ではありません`)
                    if (Number(quantity) < 0) errors.push(`${index + 1}番目：活動量がマイナスの値になってます`)

                    const [integerPart, decimalPart] = quantity.split('.')
                    if (integerPart?.length > 12) errors.push(`${index + 1}番目：最大12桁まで`)
                    if (decimalPart?.length > MAX_DECIMAL_DIGIT) errors.push(`${index + 1}番目：小数点以下${MAX_DECIMAL_DIGIT}桁まで`)

                    // 拠点ID
                    const siteId = Number(row[siteIdIndex])
                    if (site?.id !== 0) {
                        if (!childSites.some(s => s.id === siteId)) {
                            errors.push(`${index + 1}番目：拠点IDが選択された拠点もしくはその子拠点と一致していません`)
                        }
                    } else {
                        if (!storeState.sites.find(s => (s.id === siteId && s.status === 1))) {
                            errors.push(`${index + 1}番目：拠点IDが存在しません`)
                        }
                    }

                    // 年・月
                    const year = Number(row[yearIndex])
                    const month = Number(row[monthIndex])
                    if (isNaN(year) || isNaN(month) || !oneYearDates.some((date) => (date.year == year && date.month == month))) {
                        errors.push(`${index + 1}番目：年月が選択された年度内ではありません`)
                    }

                    // カテゴリ
                    const validCategory = activities?.some((a) => 
                        a.emissionFactorId == Number(row[eFIdIndex]) 
                        && a.categoryEmissionFactorTableId == Number(row[categoryIndex])
                    )
                    if (!validCategory) {
                        errors.push(`${index + 1}番目：登録済みのカテゴリではありません`)
                    }
                })

                return errors
            }

            async function importData(headlessData: Array<any>) {
                setLoading(true)
                const save_activities: Array<SaveActivityWithSiteId> = []
                headlessData.forEach((d) => {
                    const quantity = Number(d[quantityIndex])
                    const categoryId = Number(d[categoryIndex])
                    const memo = d[memoIndex] as string
                    const eF_id = Number(d[eFIdIndex])
                    const month = makeMonth(Number(d[yearIndex]), Number(d[monthIndex]))
                    const siteId = Number(d[siteIdIndex])
                    if ((quantity || d[quantityIndex] == '0') && eF_id) {
                        const registered = activities?.find((a) => {
                            return a.emissionFactorId == eF_id
                            && a.categoryEmissionFactorTableId == categoryId
                            && a.month == month
                            && a.siteId == siteId
                        })
                        save_activities.push({
                            id: registered?.id,
                            emissionFactorId: eF_id,
                            categoryEmissionFactorTableId: categoryId,
                            quantity: quantity,
                            month: month,
                            siteId: siteId,
                            memo: memo.replace(/\\n/g, '\n')
                        })
                    }
                })
                if (save_activities.length) {
                    // 月単位でactivityを一括更新するためgroupbyする
                    // 並列処理ではデッドロックが発生するため、直列に行う
                    const groupSaveActivities = groupby(save_activities, a => `${a.month}_${a.siteId}`)
                    try {
                        for (const [key, value] of Object.entries(groupSaveActivities)) {
                            const month = key.split('_')[0]
                            const siteId = Number(key.split('_')[1])

                            const saveResult = await siteActivityApi.saveSiteActivities(siteId, month, {activities: value})
                            if (!saveResult) {
                                throw new Error(`Error at saveSiteActivities: {siteId: ${siteId}, month: ${month}}`)
                            }

                            const submissionResult = await siteSubmissionApi.saveSiteSubmissions(siteId, {
                                month: month,
                                status: SubmissionStatus.Done,
                            })
                            if (!submissionResult) {
                                throw new Error(`Error at submissionResult: {siteId: ${siteId}, month: ${month}}`)
                            }
                        }

                        setLoading(false)
                        setMessage({ message: "アップロードに成功しました", type: 'success' })
                        setDoResetData((pre) => !pre)
                    } catch (error) {
                        setLoading(false)
                        setMessage({ message: "アップロードに失敗しました。", type: 'error' })
                        console.warn('Error at csv bulk upload:', error)
                    }

                } else {
                    setLoading(false)
                    setMessage({ message: "アップロード対象が存在しません", type: 'error' })
                }
            }
        }
    }

    const onYearSelected = (year: number) => {
        setAnalysisDate('year', year)
    }

    return (
        <main>
            <title>データ入力｜Emission View</title>
            <SelectorGroup
                value={site?.id ?? ALL_SITES.id}
                options={siteOptions}
                onChange={handleSiteChange}
                setSelectSite={setSelectSite}
            />

            <Space height={15}/>
            <div className={classes.operationTitle}>CSVの一括アップロード</div>
            { loading && (<div>アップロード中です...</div>)}
            <div className={classes.selectGroup}>
                <div style={{width: 224, minWidth: 224}}>
                    <Space height={20}/>
                    <YearSelector size="sm" onYearSelected={onYearSelected} year={analysisState.date.year}/>
                </div>
                <label htmlFor="item-selector-csv-handler-upload" onChange={handleFileUpload}>
                    <Input accept="text/csv" type="file" id="item-selector-csv-handler-upload"/>
                    <OutlineButton
                        startIcon={<UploadFileIcon/>}
                        style={{marginTop: 20, width: 164, height: 35, fontSize: 14, fontWeight: 300}}
                        component="span"
                        disabled={loading}
                    >
                        アップロード
                    </OutlineButton>
                </label>
            </div>
        </main>
    )
}

const Overview = () => <OverviewInner/>

export default withLayout(Overview)
